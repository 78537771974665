<template>
    <div id="chart">
        <vue-apex-charts type="radialBar" :height="chartHeight" :width="chartWidth" :options="chartOptionsComputed" :series="[value]" :alertColor="alertColor" :typeColor="typeColor"></vue-apex-charts>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        components: {
            VueApexCharts,
        },
        props: {
            value: {
                type: Number,
                default: 0,
            },
            dataLabelFontSize: {
                type: Number,
                default: 10,
            },
            chartHeight: {
                type: Number,
                default: 100,
            },
            chartWidth: {
                type: Number,
                default: 100,
            },
            alertColor: {
                type: Object,
                required: false,
            },
            typeColor: {
                type: String,
                required: false,
            },
        },

        computed: {
            chartOptionsComputed() {
                let color;

                const gradientToColors = {
                    success: '#28C76F',
                    warning: '#FF9F43',
                    danger: '#F63B51',
                };

                if (this.typeColor) {
                    if (this.typeColor === 'vigilance') {
                        color = gradientToColors.warning;
                    } else if (this.typeColor === 'critical') {
                        color = gradientToColors.danger;
                    } else {
                        color = gradientToColors.success;
                    }
                } else {
                    color = this.value < 5 ? gradientToColors.danger : this.value <= 15 ? gradientToColors.warning : gradientToColors.success;
                }

                if (this.alertColor) {
                    if (this.alertColor.danger) {
                        color = gradientToColors.danger;
                    } else if (this.alertColor.vigilant) {
                        color = gradientToColors.warning;
                    } else {
                        color = gradientToColors.success;
                    }
                }

                return {
                    chart: {
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                name: {
                                    show: false,
                                    color: '#888',
                                    fontSize: '10px',
                                },
                                value: {
                                    offsetY: 4,
                                    color: '#b4b7bd',
                                    fontSize: `${this.dataLabelFontSize}px`,
                                    show: true,
                                },
                            },
                        },
                    },
                    colors: [color],
                    grid: {
                        padding: {
                            top: 4,
                            left: 4,
                            right: 4,
                            bottom: 4,
                        },
                    },
                };
            },
        },
    };
</script>

<style scoped lang="scss">
    @import 'src/@core/scss/base/bootstrap-extended/_variables.scss';
</style>
