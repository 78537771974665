<template>
    <div>
        <div style="margin-bottom: 20px">
            <strong>{{ title || 'Campagne globale' }}</strong>
        </div>
        <b-row>
            <b-col style="margin-bottom: 10px">Date de début</b-col>
            <b-col style="margin-bottom: 10px">Date de fin</b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-input :readOnly="readOnly" v-model="valueStartDate" type="date" class="inputx" @change="autoFillEndDate(valueStartDate)" label="Nouvelle date de début:" />
            </b-col>
            <b-col>
                <b-form-input :readOnly="readOnly" v-model="valueEndDate" type="date" class="inputx" label="Nouvelle date de fin :" />
            </b-col>
        </b-row>
        <br />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    
    export default { 
        props: {
            campaign: {
                type: Object,
                required: true,
            },
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                startDate: '',
                valueStartDate: '',
                endDate: '',
                valueEndDate: '',
                title: '',
            };
        },
        computed: {
            ...mapGetters({
                formatToDate: 'app/formatToDate',
                formatToDashDate: 'app/formatToDashDate',
            }),
            getData() {
                return {
                    campaign_code: this.campaign.campaign_code,
                    start_date: this.valueStartDate,
                    end_date: this.valueEndDate,
                };
            },
        },
        watch: {
            valueStartDate() {
                this.$emit('on-date-changed', this.getData);
            },
            valueEndDate() {
                this.$emit('on-date-changed', this.getData);
            },

            campaign() {
                this.setRangeDate();
                this.autoFillEndDate(this.valueStartDate);
                this.title = this.$store.state.app.mediaMetrics.find((media) => media.media === this.campaign.media_support_code)?.label;
            },
        },
        mounted() {
            this.setRangeDate();
            this.autoFillEndDate(this.valueStartDate);
            if (this.campaign.campaign_code) {
                this.title = this.$store.state.app.mediaMetrics.find((media) => media.media === this.campaign.media_support_code)?.label;
            }
        },
        methods: {
            setRangeDate() {
                this.startDate = this.campaign.start_date;
                this.endDate = this.campaign.end_date;
                this.valueStartDate = this.formatToDashDate(this.formatToDate(this.startDate));
                this.valueEndDate = this.formatToDashDate(this.formatToDate(this.endDate));
            },
            autoFillEndDate(startDate) {
                const trueStartDate = this.formatToDate(this.startDate);
                const trueNewStartDate = this.formatToDate(startDate);
                const trueEndDate = this.formatToDate(this.endDate);

                const diffdays = Math.round((trueEndDate - trueStartDate) / (1000 * 60 * 60 * 24));

                const newEndDate = new Date(trueNewStartDate);
                newEndDate.setDate(trueNewStartDate.getDate() + diffdays);

                this.endDate = this.formatToDashDate(newEndDate);
            },
        },
    };
</script>

<style></style>
