<template>
    <b-modal v-if="isMedia" v-model="show" title="Modification de la date du média" :hide-footer="true">
        <p style="margin-top: 10px">Date actuelle : {{ currentDate }}</p>
        <br />
        <b-overlay :show="fetchingCampaignChildren">
            <range-date ref="parent-campaign-dates" :campaign="campaign" :readOnly="isReadOnly"></range-date>
            <range-date v-for="(media, index) in currentMedia" :key="index" ref="children-campaign-dates" :campaign="media" @on-date-changed="updateChildDate"></range-date>
            <b-btn @click="updateDateCampaign()" variant="primary">Validation</b-btn>
        </b-overlay>
    </b-modal>

    <b-modal v-else v-model="show" :title="'Modification de la date du média'" :hide-footer="true">
        <p style="margin-top: 10px">Date actuelle : {{ currentDate }}</p>
        <br />
        <b-overlay :show="fetchingCampaignChildren">
            <range-date ref="parent-campaign-dates" :campaign="campaign" :readOnly="isReadOnly"></range-date>

            <range-date v-for="camp in childrenCampaign" :key="camp.campaign_code" ref="children-campaign-dates" :campaign="camp" @on-date-changed="updateChildDate"></range-date>
            <b-btn @click="updateDateCampaign()" variant="primary">Validation</b-btn>
        </b-overlay>
    </b-modal>
</template>

<script>
    import RangeDate from './range-date.vue';
    import { mapGetters } from 'vuex';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

    export default {
        components: {
            RangeDate,
        },
        data() {
            return {
                campaign: {},
                childrenCampaign: [],
                show: false,
                fetchingCampaignChildren: true,
                currentDate: '',
                isReadOnly: true,
                currentMedia: [],
                parent: {},
                isMedia: false,
                isAlert: null,
                alertCode: null,
            };
        },

        computed: {
            ...mapGetters({
                formatToDate: 'app/formatToDate',
            }),
        },
        methods: {
            close() {
                this.code = '';
                this.currentDate = '';
                this.show = false;
                this.fetchingCampaignChildren = true;
            },
            open(startDate, endDateRaw, code) {
                this.isMedia = false;

                this.startDate = startDate;
                this.endDate = endDateRaw;

                this.$set(this, 'campaign', {
                    start_date: startDate,
                    end_date: endDateRaw,
                });
                this.code = code;
                this.fetchingCampaignChildren = true;
                this.show = true;
                this.$store
                    .dispatch('app/genericGet', this.$store.state.app.apiBillingiUrl.concat('/children-campaign/?code=' + code))
                    .then((res) => {
                        this.$set(this, 'childrenCampaign', res.data);
                        this.setRangeDate();
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally(() => {
                        this.fetchingCampaignChildren = false;
                    });
            },

            openMediaModal(startDate, endDateRaw, code, parent, isAlert, alertCode) {
                this.startDate = startDate;
                this.endDate = endDateRaw;
                this.parent = parent;
                this.isMedia = true;
                this.isAlert = isAlert;
                this.alertCode = alertCode;

                this.$set(this, 'campaign', {
                    start_date: parent.start_date,
                    end_date: parent.end_date,
                });
                this.code = code;

                this.fetchingCampaignChildren = true;
                this.show = true;

                this.$store
                    .dispatch('app/genericGet', this.$store.state.app.apiBillingiUrl.concat('/children-campaign/?code=' + parent.campaign_code))
                    .then((res) => {
                        this.currentMedia = res.data.find((media) => media.campaign_code === this.code);
                        this.currentMedia = [this.currentMedia];

                        this.$set(this, 'childrenCampaign', res.data);
                        this.setRangeDateMedia();
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally(() => {
                        this.fetchingCampaignChildren = false;
                    });
            },

            updateChildDate(campaign) {
                let modifiedChild = this.childrenCampaign.find((child) => child.campaign_code === campaign.campaign_code);

                modifiedChild.start_date = campaign?.start_date;
                modifiedChild.end_date = campaign?.end_date;

                this.setRangeDate();
            },

            async setRangeDateMedia() {
                let minDate = this.childrenCampaign.map((child) => child.start_date).sort((a, b) => new Date(this.formatToDate(a)) - new Date(this.formatToDate(b)))[0];
                let maxDate = this.childrenCampaign.map((child) => child.end_date).sort((a, b) => new Date(this.formatToDate(b)) - new Date(this.formatToDate(a)))[0];

                this.currentDate = new Date().toLocaleDateString();

                this.campaign.start_date = minDate;
                this.campaign.end_date = maxDate;
                await this.$refs['parent-campaign-dates'].setRangeDate();
            },

            async setRangeDate() {
                let minDate = this.childrenCampaign.map((child) => child.start_date).sort((a, b) => new Date(this.formatToDate(a)) - new Date(this.formatToDate(b)))[0];
                let maxDate = this.childrenCampaign.map((child) => child.end_date).sort((a, b) => new Date(this.formatToDate(b)) - new Date(this.formatToDate(a)))[0];

                this.currentDate = new Date().toLocaleDateString();

                this.campaign.start_date = minDate;
                this.campaign.end_date = maxDate;

                await this.$refs['parent-campaign-dates'].setRangeDate();
            },

            async updateDateCampaign() {
                const oneDayAhead = new Date();
                oneDayAhead.setDate(oneDayAhead.getDate() + 1); // Jour + 1

                let parentCampaignData = this.$refs['parent-campaign-dates'].getData;

                parentCampaignData.campaign_code = this.code;

                let campaignsDatesToUpdate = [parentCampaignData];

                for (let camp of this.$refs['children-campaign-dates']) {
                    campaignsDatesToUpdate.push(camp.getData);
                }

                const wanted = this.childrenCampaign.find((element) => element.media_support_code === 'ADS');

                if (wanted != undefined) {
                    var data = {
                        code: wanted.campaign_code,
                        data: {
                            startDate: wanted.start_date,
                            endDate: wanted.end_date,
                        },
                    };

                    this.$root.toggleGlobalLoading(true);

                    this.$store
                        .dispatch('app/updateCampaignDateFtv', data)
                        .then((res) => {
                            if (res.data.purchaseItemList[0].status === 'updated') {
                                this.$emit('on-update-date-campaign', campaignsDatesToUpdate);
                            }
                            this.$root.toggleGlobalLoading(false);
                        })
                        .catch((error) => {
                            if (error.response.data.status === 422) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "La date de début ou de fin doit être supérieure ou égale à celle d'aujourd'hui",
                                        message: error.response.data.extra[0],
                                        icon: 'exclamation-triangle',
                                        variant: 'danger',
                                    },
                                });
                            }
                            this.$root.toggleGlobalLoading(false);
                        });
                } else {
                    if (this.isAlert) {
                        const media = this.currentMedia.find((media) => media.start_date);

                        const startDate = new Date(media.start_date);

                        if (startDate > oneDayAhead) {
                            // Supprimer l'alerte
                            await this.$store.dispatch('app/deleteAlert', {
                                code: this.alertCode,
                            });
                        } else {
                            await this.$store.dispatch('app/updateDateAlert', {
                                code: this.alertCode,
                                data: { start_date: media?.start_date, end_date: media?.end_date },
                            });
                        }
                    }
                    this.$emit('on-update-date-campaign', campaignsDatesToUpdate);
                }
            },
        },
    };
</script>

<style></style>
